import React, { useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Headroom from 'react-headroom'
import MenuHamburger from './MenuHamburger'
import Profile from '../Profile/Profile'
import MenuHorizontal from './MenuHorizontal'
import MicroFrontend from '../../MicroFrontend'
import { useSelector } from 'react-redux'
import { IReducer } from '../../interface'
import { ConfigContext } from '../../App'
import { useCookies } from 'react-cookie'
import { Stack, Box } from '@mui/material'

const { REACT_APP_CART_HOST: CartHost } = process.env

const headroomStyle = {
  zIndex: 100
}

export default function NavBar() {
  const stullerAccountObj = useSelector((state: IReducer) => state.accountReducer.stullerAccountObj)
  const stripeInfo = useSelector((state: IReducer) => state.stripeAccountReducer.stripeInfo)
  const [cookies] = useCookies(['temporary_user_id'])
  const configContext = React.useContext(ConfigContext)

  const sendDatasHandler = useCallback(() => {
    window.dispatchEvent(new CustomEvent('getUserId', { detail: cookies.temporary_user_id }))
    window.dispatchEvent(new CustomEvent('getStullerAccount', { detail: stullerAccountObj }))
  }, [stullerAccountObj, cookies.temporary_user_id])

  useEffect(() => {
    sendDatasHandler()
  }, [stripeInfo, sendDatasHandler])

  useEffect(() => {
    window.addEventListener('onReadyMicrofrontendCart', sendDatasHandler)
    return () => {
      window.removeEventListener('onReadyMicrofrontendCart', sendDatasHandler)
    }
  }, [sendDatasHandler])

  return (
    <Headroom style={headroomStyle}>
      <div className='nav'>
        <Stack
          direction='row'
          role='navigation'
          alignItems='center'
          justifyContent={'space-between'}
          sx={{ width: '100%', height: '100%', maxWidth: 1200, px: { xs: 0, md: 1 } }}
        >
          <Link to='/' className='navbar-logo'>
            <Box
              component='img'
              sx={{
                width: { xs: 20, s: 96 },
                height: 'auto',
                display: 'flex',
                mx: 2,
                content: {
                  xs: 'url(https://gemvision.com/Images/gemvision_logo_g_white.svg)',
                  s: 'url(https://gemvision.com/Images/gemvision_logo_white.svg)'
                }
              }}
              alt='Gemvision'
              src='https://gemvision.com/Images/gemvision_logo_white.svg'
            />
          </Link>
          {!configContext.isSmallScreen && <MenuHorizontal />}
          <Stack direction='row' pr={1}>
            <MicroFrontend host={CartHost} name='Cart' />
            <Profile />
            {configContext.isSmallScreen && <MenuHamburger />}
          </Stack>
        </Stack>
      </div>
    </Headroom>
  )
}
