import { FormattedMessage, useIntl } from 'react-intl'
import { Form, Field, FieldRenderProps } from 'react-final-form'
import { Typography, Button, FormControlLabel, Checkbox, FormHelperText, Paper } from '@mui/material'
import validateSendEmail from '../../validators/validateSendEmail'
import { Link } from 'react-router-dom'
import TextFieldReduxForm from '../Common/TextFieldReduxForm'
import { IContactUsForm } from '../../interface'

/**
 * Contact Us form
 * @param onSubmit On submit function
 * @param initialValuesData The initial values of the form
 * @param isLoading Whether the form is loading
 * @param disabledField Boolean value indicating whether form Fields are disabled
 * @returns Form render
 */
const ContactUsForm = (props: {
  onSubmit: (data: IContactUsForm) => void
  initialValuesData: IContactUsForm | {}
  isLoading: boolean
  disabledField: boolean
}) => {
  const intl = useIntl()
  const { onSubmit, initialValuesData, isLoading, disabledField } = props

  /**
   * Checkbox component to be passed as prop on Field
   * @param params Field params from the form
   * @return html tag containing the checkbox and the renderFromHelper function
   */
  const renderCheckbox = (params: FieldRenderProps<boolean>) => {
    const { meta, input, title, dataCy } = params

    return (
      <div style={{ width: '100%', marginTop: '1rem', textAlign: 'left' }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={input.value ? true : false}
              onChange={(event) => input.onChange(event.target.checked)}
              color='primary'
            />
          }
          label={title}
          data-cy={dataCy}
        />
        {renderFromHelper(meta.touched!, meta.error)}
      </div>
    )
  }

  /**
   * Render error message for the checkbox field
   * @param touched A boolean value to control error
   * @param error The actual error
   * @returns a HelperText component with the error message
   */
  const renderFromHelper = (touched: boolean, error: any) => {
    if (!(touched && error)) {
      return
    } else {
      return <FormHelperText style={{ color: 'red' }}>{touched && error}</FormHelperText>
    }
  }

  /**
   * Text area component to be passed as prop by Field
   * @param params Values from Field form
   * @returns HTML textarea element
   */
  const renderTextArea = (params: FieldRenderProps<string>) => {
    return <textarea placeholder={params.title} cols={40} rows={5} {...params.input} />
  }

  return (
    <Paper style={{ maxWidth: 480, padding: '3rem 2rem', margin: 'auto' }}>
      <Typography variant='h6' style={{ marginBottom: '2rem' }}>
        <FormattedMessage id='common.contactUs' />
      </Typography>
      <Form validate={validateSendEmail} onSubmit={onSubmit} initialValues={initialValuesData}>
        {({ handleSubmit, pristine, submitting }) => (
          <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <Field
              disabled={disabledField}
              name='name'
              title={intl.formatMessage({ id: 'contactUs.name' })}
              component={TextFieldReduxForm}
              dataCy='name-input'
            />
            <Field
              disabled={disabledField}
              name='email'
              title={intl.formatMessage({ id: 'account.email' })}
              component={TextFieldReduxForm}
              dataCy='email-input'
            />
            <Field
              type='tel'
              name='phone'
              title={intl.formatMessage({ id: 'account.phone' })}
              component={TextFieldReduxForm}
              dataCy='phone-input'
            />
            <Field name='comment' title={intl.formatMessage({ id: 'contactUs.comment' })} component={renderTextArea} />
            <Field
              name='acceptTerms'
              title={
                <FormattedMessage
                  id='account.iHaveReadAndAccepted'
                  values={{
                    privacyPolicy: (
                      <Link to='/legal/privacy' target='_blank' rel='noopener noreferrer'>
                        <FormattedMessage id='account.privacyPolicy' />
                      </Link>
                    )
                  }}
                />
              }
              component={renderCheckbox}
              dataCy='privacy-checkbox'
            />
            <Button
              type='submit'
              disabled={pristine || submitting || isLoading}
              variant='contained'
              color='primary'
              size='large'
              data-cy='send-email-button'
            >
              <FormattedMessage id='contactUs.sendEmail' />
            </Button>
          </form>
        )}
      </Form>
    </Paper>
  )
}

export default ContactUsForm
