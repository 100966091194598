import { CHANGE_LANGUAGE } from '../actions'
import SPANISH_STATE from '../resources/es.json'
import ENGLISH_STATE from '../resources/en.json'
import { detectLanguage } from '../functions/detectLanguage'
import { IActionPayload } from '../interface'

const initialState = {
  availableLanguages: [
    { key: 'en', text: 'English', value: 'en' },
    { key: 'es', text: 'Español (Spanish)', value: 'es' }
  ],
  ...detectLanguage()
}

export default (state = initialState, action: IActionPayload) => {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      if (action.payload === 'es') {
        return { ...state, lang: action.payload, messages: SPANISH_STATE }
      } else if (action.payload === 'en') {
        return { ...state, lang: action.payload, messages: ENGLISH_STATE }
      } else {
        return { ...state, lang: 'en', messages: ENGLISH_STATE }
      }
    default:
      return state
  }
}
