import React from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'
import { Typography, Grid } from '@mui/material'
import { FormattedMessage } from 'react-intl'

const FooterMenu = () => {
  return (
    <Grid className='footernav'>
      <Grid item>
        <Typography>
          <FormattedMessage id='nav.products' />
        </Typography>
        <Link to='/matrixgold' className='footer-link'>
          <FormattedMessage id='nav.matrixgold' />
        </Link>
        <Link to='/countersketch' className='footer-link'>
          <FormattedMessage id='nav.countersketch' />
        </Link>
      </Grid>
      <Grid item>
        <Typography>
          <FormattedMessage id='nav.education' />
        </Typography>
        <Link to='/matrixgold/learning' className='footer-link'>
          <FormattedMessage id='nav.matrixgold' />
        </Link>
        <Link to='/countersketch/learning' className='footer-link'>
          <FormattedMessage id='nav.countersketch' />
        </Link>
        <Link to='/educational-partners' className='footer-link'>
          <FormattedMessage id='learning.educationalPartners' />
        </Link>
      </Grid>
      <Grid item>
        <Typography>
          <FormattedMessage id='nav.contact' />
        </Typography>
        <Link to='/tech-support' className='footer-link'>
          <FormattedMessage id='nav.support' />
        </Link>
        <Link to='/sales' className='footer-link'>
          <FormattedMessage id='nav.sales' />
        </Link>
      </Grid>
      <Grid item>
        <Typography>
          <FormattedMessage id='nav.buy' />
        </Typography>
        <Link to='/store' className='footer-link'>
          <FormattedMessage id='nav.store' />
        </Link>
      </Grid>
      <Grid item>
        <Typography>
          <FormattedMessage id='nav.relatedSites' />
        </Typography>
        <Link to={'https://www.stuller.com/'} className='footer-link' target='_blank' rel='noopener noreferrer'>
          Stuller.com
        </Link>
        <Link to={'https://blog.stuller.com/'} className='footer-link' target='_blank' rel='noopener noreferrer'>
          Stuller Blog
        </Link>
        <Link
          to={'https://www.stuller.com/benchjeweler'}
          className='footer-link'
          target='_blank'
          rel='noopener noreferrer'
        >
          Stuller Benchjeweler
        </Link>
      </Grid>
    </Grid>
  )
}

export default FooterMenu
