import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Button, ClickAwayListener, Fade, Paper, Popper, MenuList, Stack } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { FormattedMessage } from 'react-intl'
import './NavBar.css'

export default function MenuHorizontal() {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLButtonElement>(null)
  const location = useLocation()

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus()
    }

    prevOpen.current = open
  }, [open])

  return (
    <Stack
      direction={'row'}
      sx={{ height: 48, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
    >
      <Button
        disableTouchRipple
        className={location.pathname.startsWith('/matrixgold') ? 'nav-btn nav-selected' : 'nav-btn'}
        component={Link}
        to='/matrixgold'
      >
        <FormattedMessage id='nav.matrixgold' />
      </Button>
      <Button
        disableTouchRipple
        className={location.pathname.startsWith('/countersketch') ? 'nav-btn nav-selected' : 'nav-btn'}
        component={Link}
        to='/countersketch'
      >
        <FormattedMessage id='nav.countersketch' />
      </Button>
      <Button
        disableTouchRipple
        className={location.pathname.startsWith('/news') ? 'nav-btn nav-selected' : 'nav-btn'}
        component={Link}
        to='/news'
      >
        <FormattedMessage id='common.news' />
      </Button>
      <Button
        disableRipple
        endIcon={<ExpandMore className='nav-btn-arrow' sx={{ ml: -1, transform: open ? 'rotate(180deg)' : 'none' }} />}
        className='nav-btn'
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup='true'
        onClick={handleToggle}
      >
        <FormattedMessage id='nav.contact' />
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Fade in={open} {...TransitionProps} style={{ transformOrigin: placement }}>
            <Paper elevation={1} sx={{ bgcolor: '#282828' }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  className='nav-menu-list'
                  autoFocusItem={open}
                  id='menu-list-grow'
                  onKeyDown={handleListKeyDown}
                >
                  <Button
                    disableTouchRipple
                    className='nav-btn nav-align-left'
                    onClick={(event: any) => {
                      handleClose(event)
                    }}
                    component={Link}
                    to='/tech-support'
                  >
                    <FormattedMessage id='nav.support' />
                  </Button>
                  <Button
                    disableTouchRipple
                    className='nav-btn nav-align-left'
                    component={Link}
                    to='/sales'
                    data-cy='sales-button'
                    onClick={(event: any) => {
                      handleClose(event)
                    }}
                  >
                    <FormattedMessage id='nav.sales' />
                  </Button>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
      <Button
        disableTouchRipple
        className={location.pathname.startsWith('/store') ? 'nav-btn nav-selected' : 'nav-btn'}
        data-cy='store-button'
        component={Link}
        to='/store'
      >
        <FormattedMessage id='nav.store' />
      </Button>
    </Stack>
  )
}
