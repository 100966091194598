import React from 'react'
import axios from 'axios'
import { FormattedMessage } from 'react-intl'
import { IUserInfo, IDealerWorld, IContactUs } from '../interface'
import { BASE_URL_GEMVISION, BASE_URL_STRIPE } from '../constants'

/**
 * Sends request to /sold endpoint and dispatch an event. If error, dipatch an event error
 * @param userInfo - The user information.
 * @param {string} productInfo - product name.
 * @param {string} comments - User comments on the form.
 * @param cancelSale - A function to be executed once request has been done without error
 * @returns  status code
 */
export const sendEmailToDealer = (
  userInfo: IUserInfo | { FirstName: string; Email: string; PhoneNumber?: string; AccountNumber?: string },
  productInfo: string,
  dealersToSendEmail: IDealerWorld[],
  comments: string,
  cancelSale: () => void
) => {
  return axios
    .post(`${BASE_URL_GEMVISION}/sold`, {
      userInfo,
      productInfo,
      dealersToSendEmail,
      comments
    })
    .then(() => {
      window.dispatchEvent(
        new CustomEvent('sendNotification', {
          detail: { type: 'success', message: <FormattedMessage id='notification.messageSent' /> }
        })
      )
      cancelSale()
    })
    .catch(() => {
      window.dispatchEvent(
        new CustomEvent('sendNotification', {
          detail: { type: 'danger', message: <FormattedMessage id='notification.systemError' /> }
        })
      )
    })
}

/**
 * Sends request to /training endpoint, dispatch an event and return status code. If error, dipatch an event error
 * @param userInfo - The user information.
 * @param {string} productInfo - product name.
 * @param {string} comments - User comments on the form.
 * @returns  status code
 */
export const sendEmailToTraining = async (userInfo: IContactUs, productInfo: string, comments: string) => {
  let request

  try {
    request = await axios.post(`${BASE_URL_GEMVISION}/training`, { userInfo, productInfo, comments })
    if (request.status === 200) {
      window.dispatchEvent(
        new CustomEvent('sendNotification', {
          detail: { type: 'success', message: <FormattedMessage id='notification.messageSent' /> }
        })
      )
      return request.status
    }
    throw Error('Something went wrong')
  } catch (error: any) {
    console.error('Error on sendEmailToTraining - ', error.message)
    window.dispatchEvent(
      new CustomEvent('sendNotification', {
        detail: { type: 'danger', message: <FormattedMessage id='notification.systemError' /> }
      })
    )
    return 500
  }
}

export const updateStripeAccountData = async (stullerUser: any, stripeAccount: any) => {
  try {
    const newData = await axios.put(`${BASE_URL_STRIPE}/customer`, {
      customerId: stripeAccount.id,
      metadata: {
        LoginId: stullerUser.LoginId,
        rjoElegible: stullerUser.rjoelegible,
        stullerBillingAccount: stullerUser.BillToAccount,
        stullerDefaultShipToAccount: stullerUser.DefaultShipToAccount
      },
      shipping: {
        address: {
          city: stullerUser.BillToAccountObj.City,
          country: stullerUser.BillToAccountObj.Country,
          line1: stullerUser.BillToAccountObj.AddressLine1,
          postal_code: stullerUser.BillToAccountObj.Zip,
          state: stullerUser.BillToAccountObj.State
        },
        name: `${stullerUser.FirstName} ${stullerUser.LastName}`,
        phone: stullerUser.BillToAccountObj.PhoneNumber
      }
    })
    console.log('User data updated')
    return newData
  } catch (error: any) {
    console.error('Error on updateStripeAccount - ', error)
    throw error
  }
}

// Note: Next method is duplicated on internal they should be in a common source
/**
 * Compare metadatas from stuller and stripe to know if stripe must be updated
 * @param stullerUser
 * @param stripeAccount
 * @returns boolean
 */
export const checkAccountData = (stullerUser: any, stripeAccount: any) => {
  const stripeStullerFields: { [key: string]: string } = {
    //fields to compare (stripeAccountField: stullerUserField)
    'metadata.LoginId': 'LoginId',
    'metadata.rjoElegible': 'rjoelegible',
    'metadata.stullerBillingAccount': 'BillToAccount',
    'metadata.stullerDefaultShipToAccount': 'DefaultShipToAccount',
    'shipping.address.city': 'BillToAccountObj.City',
    'shipping.address.country': 'BillToAccountObj.Country',
    'shipping.address.line1': 'BillToAccountObj.AddressLine1',
    'shipping.address.postal_code': 'BillToAccountObj.Zip',
    'shipping.address.state': 'BillToAccountObj.State',
    'shipping.phone': 'BillToAccountObj.PhoneNumber'
  }

  try {
    return Object.keys(stripeStullerFields).some((field: string) => {
      const stripeField = field.split('.').reduce((a: any, b: string) => a[b], stripeAccount)
      const stullerField = stripeStullerFields[field]
        .split('.')
        .reduce((a: any, b: string) => a[b], stullerUser)
        .toString()
      return stripeField.toLowerCase() !== stullerField.toLowerCase()
    })
  } catch (error: any) {
    console.warn('Warning on checkAccountData -', error?.message)
    return true
  }
}
