import React, { useEffect, useState } from 'react'
import { Navigate, Routes, Route, useLocation, useNavigate } from 'react-router-dom'
import { getHistoryPayments, saveRootBeforeLogin, getStripeAccount } from './actions'
import { useDispatch } from 'react-redux'
import SimpleSpinner from './components/Common/SimpleSpinner'
import ProtectedRoute from './ProtectedRoute'
import ContactUsTraining from './containers/ContactUs/ContactUsTraining'

const JobOfferWeb = React.lazy(() => import('./components/JobOffers/JobOfferWeb'))
const JobOfferCad = React.lazy(() => import('./components/JobOffers/JobOfferCad'))
const Matrixgold = React.lazy(() => import('./containers/MatrixGold/Matrixgold'))
const EducationalParners = React.lazy(() => import('./containers/Learning/EducationalPartnersMap'))
const EndOfSupport = React.lazy(() => import('./containers/EndOfSupport'))
const FAQ = React.lazy(() => import('./containers/FAQ'))
const Legal = React.lazy(() => import('./containers/Legal/Legal'))
const UserArea = React.lazy(() => import('./components/UserArea'))
const CounterSketch = React.lazy(() => import('./containers/CounterSketch'))
const Login = React.lazy(() => import('./containers/Login'))
const OptOut = React.lazy(() => import('./containers/Legal/OptOut'))
const TechSupport = React.lazy(() => import('./containers/TechSupport'))
const Store = React.lazy(() => import('./containers/Store'))
const Error404 = React.lazy(() => import('./containers/Error404'))
const Sales = React.lazy(() => import('./containers/Sales'))
const Checkout = React.lazy(() => import('./containers/Checkout'))
const ContactUsDealers = React.lazy(() => import('./containers/ContactUs/ContactUsDealers'))
const News = React.lazy(() => import('./containers/News/News'))
const Accommodations = React.lazy(() => import('./containers/Accommodations/Accommodations'))
const Home = React.lazy(() => import('./containers/Home/Home'))
const TrainingConfirmation = React.lazy(() => import('./containers/TrainingConfirmation'))
const ServerUpdate = React.lazy(() => import('./containers/ServerUpdate'))

const GemvisionRoutes = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [selectedProduct, setSelectedProduct] = useState('')

  const changeRoute = (event: any) => {
    console.log('changeRoute', event.detail)
    navigate(event.detail)
  }

  const getProductContactUs = (event: any) => {
    setSelectedProduct(event.detail.product)
    const copy = { ...event, detail: event.detail.url }
    changeRoute(copy)
  }

  const successPurchase = (event: any) => {
    dispatch(getStripeAccount(event.detail.email))
    dispatch(getHistoryPayments(event.detail.customerId))
  }

  useEffect(() => {
    if (pathname !== '/login' && pathname !== '/create-account') {
      dispatch(saveRootBeforeLogin(pathname))
    }
    window.scrollTo(0, 0)
  }, [pathname, dispatch])

  useEffect(() => {
    window.addEventListener('changeRoute', changeRoute)
    window.addEventListener('sendProductContactUs', getProductContactUs)
    window.addEventListener('successPurchase', successPurchase)
    return () => {
      window.removeEventListener('changeRoute', changeRoute)
      window.removeEventListener('sendProductContactUs', getProductContactUs)
      window.removeEventListener('successPurchase', successPurchase)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <React.Suspense fallback={<SimpleSpinner />}>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/matrixgold/*' element={<Matrixgold />} />
        <Route path='/countersketch/*' element={<CounterSketch />} />
        <Route path='/job/044' element={<JobOfferWeb />} />
        <Route path='/job/043' element={<JobOfferCad />} />
        <Route path='/educational-partners' element={<EducationalParners />} />
        <Route path='/end-of-support' element={<EndOfSupport />} />
        <Route path='/faq' element={<FAQ />} />
        <Route path='/legal/*' element={<Legal />} />
        <Route path='/server-update' element={<ServerUpdate />} />
        <Route
          path='/user-area/*'
          element={
            <ProtectedRoute>
              <UserArea />
            </ProtectedRoute>
          }
        />
        <Route path='/login' element={<Login />} />
        <Route path='/opt-out' element={<OptOut />} />
        <Route path='/tech-support' element={<TechSupport />} />
        <Route path='/store/*' element={<Store />} key={Math.random()} />
        <Route path='/micro-frontend-store' element={<Navigate to='/store' />} />
        <Route path='/sales' element={<Sales />} />
        <Route
          path='/checkout/*'
          element={
            <ProtectedRoute>
              <Checkout />
            </ProtectedRoute>
          }
        />
        <Route path='/sendEmail' element={<ContactUsDealers selectedProduct={selectedProduct} />}></Route>
        <Route
          path='/private-classes'
          element={
            <ProtectedRoute>
              <ContactUsTraining selectedProduct={selectedProduct} />
            </ProtectedRoute>
          }
        />
        <Route
          path='/private-classes/confirmation'
          element={
            <ProtectedRoute>
              <TrainingConfirmation />
            </ProtectedRoute>
          }
        />
        <Route path='/news' element={<News />} />
        <Route path='/news/:slug' element={<News activeSlug={true} />} />
        <Route path='/accommodations' element={<Accommodations />} />
        <Route path='*' element={<Error404 />} />
      </Routes>
    </React.Suspense>
  )
}

export default GemvisionRoutes
