import React from 'react'
import { Popper, Fade, Paper, ClickAwayListener, ToggleButtonGroup, ToggleButton, IconButton } from '@mui/material'
import { Language, Check } from '@mui/icons-material'
import { onChangeLanguage } from '../actions'
import { useSelector, useDispatch } from 'react-redux'
import { IReducer } from '../interface'
import { useCookies } from 'react-cookie'

export default function Region() {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLButtonElement>(null)
  const changeLanguage = useSelector((state: IReducer) => state.changeLanguage)
  const [region, setRegion] = React.useState(changeLanguage.lang)
  const dispatch = useDispatch()
  const [, setCookies] = useCookies(['language'])

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const handleChange = (event: React.MouseEvent<HTMLElement>, newLanguage: string) => {
    event.preventDefault()
    if (newLanguage) {
      setRegion(newLanguage)
      setCookies('language', newLanguage, { path: '/' })
      dispatch(onChangeLanguage(newLanguage))
    }
  }

  return (
    <>
      <IconButton
        color='secondary'
        disableTouchRipple
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup='true'
        onClick={handleToggle}
      >
        <Language />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement='bottom-end'
        sx={{ zIndex: 9 }}
      >
        {({ TransitionProps, placement }) => (
          <Fade {...TransitionProps} style={{ transformOrigin: placement }}>
            <Paper elevation={1} sx={{ bgcolor: '#282828' }}>
              <ClickAwayListener onClickAway={handleClose}>
                <ToggleButtonGroup
                  orientation='vertical'
                  color='secondary'
                  value={region}
                  exclusive
                  onClick={handleClose}
                  onChange={handleChange}
                  aria-label='Platform'
                >
                  {changeLanguage.availableLanguages.map((value, index) => (
                    <ToggleButton value={value.key} disableRipple key={index}>
                      <Check sx={{ mr: 1, width: 16, opacity: region === value.key ? 1 : 0 }} />
                      {value.text}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  )
}
