import axios from 'axios'
import { ICartItem } from '../interface'
import { BASE_URL_GEMVISION, MilliSecondsInAWeek } from '../constants'
import { v1 as uuidv1 } from 'uuid'

export const removeProductFromCart = (cart: ICartItem[], element: ICartItem) =>
  cart.filter((cartItem) => cartItem.productId !== element.productId || cartItem.boxId !== element.boxId)

export const getCountryOfUser = () => {
  // URL HARDCODED, BECAUSE WITH THE DOMAIN THERE ISN'T RETURN COUNTRY CODE
  return axios.get('https://ghyw15qbci.execute-api.us-east-1.amazonaws.com/prod/gemvision/country').then(({ data }) => {
    let country_code = 'us'
    if ('country' in data) {
      if (typeof data.country === 'string') {
        country_code = data.country.toLowerCase()
      }
    }
    return country_code
  })
}

export const getTimeWithoutTimezone = () => {
  return Date.now() - new Date().getTimezoneOffset() * 60 * 1000
}

export const updateTTLUserId = (id?: string) => {
  const temporary_user_id = id ? id : `temporary_user_id-${uuidv1()}`
  const date = new Date()
  date.setTime(date.getTime() + MilliSecondsInAWeek)
  return { temporary_user_id, date }
}

export const getApiKey = async (parameterName: string) => {
  try {
    const key = await axios.post(`${BASE_URL_GEMVISION}/recaptcha`, { parameterName })
    return key.data
  } catch (error: any) {
    console.error(error.message)
  }
}
