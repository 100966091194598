import React from 'react'
import {
  Popper,
  Fade,
  Paper,
  ClickAwayListener,
  Button,
  MenuList,
  IconButton,
  Typography,
  Box,
  Divider
} from '@mui/material'
import { Link } from 'react-router-dom'
import { Menu, Close } from '@mui/icons-material'
import { FormattedMessage } from 'react-intl'
import './NavBar.css'

export default function MenuHamburger() {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLButtonElement>(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  return (
    <>
      <IconButton
        color='secondary'
        disableTouchRipple
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup='true'
        onClick={handleToggle}
      >
        {open ? <Close /> : <Menu />}
      </IconButton>
      <Popper
        sx={{
          marginX: '.5rem !important',
          width: 'calc(100% - 1rem)',
          maxWidth: 400,
          transform: { xs: 'translate3d(0, 54px, 0) !important', sm: 'auto' }
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement='bottom-end'
      >
        {({ TransitionProps, placement }) => (
          <Fade in={open} {...TransitionProps} style={{ transformOrigin: placement }}>
            <Paper elevation={1} sx={{ bgcolor: '#282828', width: '100%', borderRadius: 2 }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  sx={{ p: 4, display: 'flex', flexDirection: 'column', textAlign: 'left' }}
                  autoFocusItem={open}
                  id='menu-list-grow'
                >
                  <Typography variant='body2' sx={{ color: '#bbb', mb: 1 }}>
                    <FormattedMessage id='nav.products' />
                  </Typography>
                  <Button
                    disableTouchRipple
                    className='nav-btn hamburger-button-with-icon'
                    onClick={handleClose}
                    component={Link}
                    to='/matrixgold'
                    startIcon={
                      <Box
                        component='img'
                        height={20}
                        mr={1}
                        src='https://gemvision.com/Images/MatrixGold/matrixgold-white-icon.svg'
                      />
                    }
                  >
                    <FormattedMessage id='nav.matrixgold' />
                  </Button>
                  <Button
                    disableTouchRipple
                    className='nav-btn hamburger-button-with-icon'
                    onClick={handleClose}
                    component={Link}
                    to='/countersketch'
                    startIcon={
                      <Box
                        component='img'
                        height={20}
                        mr={1}
                        src='https://gemvision.com/Images/CounterSketch/countersketch-white-icon.svg'
                      />
                    }
                  >
                    <FormattedMessage id='nav.countersketch' />
                  </Button>
                  <Divider sx={{ borderColor: '#444', margin: '1rem 0' }} />
                  <Button
                    disableTouchRipple
                    className='nav-btn nav-align-left'
                    onClick={handleClose}
                    component={Link}
                    to='/news'
                  >
                    <FormattedMessage id='common.news' />
                  </Button>
                  <Button
                    disableTouchRipple
                    className='nav-btn nav-align-left'
                    onClick={handleClose}
                    component={Link}
                    to='/tech-support'
                  >
                    <FormattedMessage id='nav.support' />
                  </Button>
                  <Button
                    disableTouchRipple
                    className='nav-btn nav-align-left'
                    component={Link}
                    to='/sales'
                    data-cy='sales-button'
                    onClick={handleClose}
                  >
                    <FormattedMessage id='nav.sales' />
                  </Button>
                  <Button
                    disableTouchRipple
                    className='nav-btn nav-align-left'
                    onClick={handleClose}
                    component={Link}
                    to='/store'
                  >
                    <FormattedMessage id='nav.store' />
                  </Button>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  )
}
