import { createTheme } from '@mui/material/styles'
import { Typography, Box } from '@mui/material'
import { styled } from '@mui/styles'
import '@mui/material'

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true
    s: true
    sm: true
    md: true
    lg: true
    xl: true
  }
}

const mainColor = '#28f'

const theme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#181818'
    },
    primary: {
      main: mainColor
    },
    secondary: {
      main: '#eee'
    },
    /* Used for dark buttons */
    info: {
      main: '#222'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      s: 300,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  }
})

export const darkTheme = createTheme(theme, {
  components: {
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontFamily: 'Inter',
          color: theme.palette.text.primary,
          fontWeight: 700,
          [theme.breakpoints.down('sm')]: {
            fontSize: '1.75rem'
          },
          [theme.breakpoints.between('sm', 'xl')]: {
            fontSize: '2.5rem'
          },
          [theme.breakpoints.up('xl')]: {
            fontSize: '3rem'
          }
        },
        h2: {
          fontFamily: 'Inter',
          fontWeight: 700,
          color: theme.palette.text.primary,
          [theme.breakpoints.down('sm')]: {
            fontSize: '1.5rem'
          },
          [theme.breakpoints.between('sm', 'xl')]: {
            fontSize: '2rem'
          },
          [theme.breakpoints.up('xl')]: {
            fontSize: '2.25rem'
          }
        },
        h3: {
          color: theme.palette.text.primary,
          fontWeight: 600,
          [theme.breakpoints.down('sm')]: {
            fontSize: '1.5rem'
          },
          [theme.breakpoints.up('sm')]: {
            fontSize: '2rem'
          }
        },
        h4: {
          color: theme.palette.text.primary,
          fontWeight: 500,
          [theme.breakpoints.down('sm')]: {
            fontSize: '1.25rem'
          },
          [theme.breakpoints.up('sm')]: {
            fontSize: '1.5rem'
          }
        },
        h6: {
          color: theme.palette.text.primary,
          fontWeight: 500,
          [theme.breakpoints.down('sm')]: {
            fontSize: '1rem'
          },
          [theme.breakpoints.up('sm')]: {
            fontSize: '1.1rem'
          }
        },
        body1: {
          color: theme.palette.text.secondary,
          lineHeight: '1.6',
          [theme.breakpoints.down('sm')]: {
            fontSize: '.95rem'
          }
        },
        body2: {
          color: theme.palette.text.secondary,
          [theme.breakpoints.down('sm')]: {
            fontSize: '.8rem'
          },
          [theme.breakpoints.up('sm')]: {
            fontSize: '.9rem'
          }
        },
        subtitle1: {
          color: theme.palette.text.secondary,
          fontWeight: 500,
          [theme.breakpoints.down('sm')]: {
            fontSize: '.9rem'
          }
        },
        gutterBottom: {
          marginBottom: '.75em'
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        root: {
          borderRadius: 999,
          textTransform: 'inherit'
        },
        sizeMedium: {
          padding: '.5rem 1.25rem'
        },
        sizeLarge: {
          padding: '.75rem 2rem'
        }
      }
    },
    MuiCardMedia: {
      styleOverrides: {
        root: {
          paddingTop: '56.25%' // 16:9
        }
      }
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          margin: '4rem auto 6rem',
          '@media (min-width: 600px)': {
            paddingLeft: '2rem',
            paddingRight: '2rem'
          }
        },
        maxWidthLg: {
          '@media (min-width: 1080px)': {
            maxWidth: 1080
          }
        }
      }
    },
    MuiTabs: {
      defaultProps: {
        variant: 'scrollable'
      },
      styleOverrides: {
        indicator: {
          backgroundColor: '#fff',
          height: 2,
          borderRadius: 2,
          bottom: 1
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: '#bbb',
          textTransform: 'uppercase',
          fontSize: '.75rem',
          letterSpacing: 0.1,
          minHeight: 60,
          minWidth: 32,
          fontWeight: 500,
          transition: 'color .2s ease',
          '&:hover': {
            textDecoration: 'none',
            color: '#fff'
          },
          '&.Mui-selected': {
            color: '#fff'
          }
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          color: '#fff',
          fontSize: 16,
          fontWeight: 600,
          width: 28,
          height: 28,
          backgroundColor: mainColor
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '.9rem',
          minHeight: 0
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: '.9rem'
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: '#444',
          margin: '2rem auto'
        }
      }
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          padding: '8px 0'
        }
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          padding: '4px 16px',
          color: '#bbb',
          fontSize: '.82rem',
          border: 'none',
          justifyContent: 'flex-start',
          borderRadius: 0,
          transition: 'all .2s ease',
          '&:hover': {
            background: 'transparent',
            color: '#fff'
          },
          '&.Mui-selected': {
            color: '#fff',
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: 'transparent'
            }
          }
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'auto',
          fontSize: '.85rem',
          fontWeight: 500
        }
      }
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: {
          transition: 'opacity .2s ease',
          '&:hover': {
            opacity: 1
          }
        },
        sizeMedium: {
          borderRadius: 12,
          opacity: 0.7,
          padding: 0,
          width: 48,
          height: 48
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeMedium: {
          fontSize: 26
        }
      }
    },
    MuiAccordion: {
      defaultProps: {
        elevation: 0,
        square: true
      },
      styleOverrides: {
        root: {
          background: '#222',
          borderRadius: 12,
          '&.Mui-expanded': {
            margin: 0
          },
          '::before': {
            display: 'none'
          }
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        expandIconWrapper: {
          color: '#eee'
        },
        root: {
          color: '#fff',
          fontWeight: 500,
          fontSize: '1.2rem',
          [theme.breakpoints.down('sm')]: {
            fontSize: '1rem'
          },
          padding: 28,
          textAlign: 'left',
          minHeight: 0,
          expanded: {
            minHeight: 300
          },
          '&.Mui-expanded': {
            minHeight: 0,
            margin: 0
          }
        },
        content: {
          margin: 0,
          expanded: {
            margin: 0
          },
          '&.Mui-expanded': {
            minHeight: 0,
            margin: 0
          }
        }
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          textAlign: 'left',
          padding: 28,
          paddingTop: 0,
          marginTop: -4
        }
      }
    },
    MuiSkeleton: {
      defaultProps: {
        animation: 'wave'
      },
      styleOverrides: {
        root: {
          transform: 'scale(1)',
          background: '#282828',
          borderRadius: 8
        }
      }
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0
      },
      styleOverrides: {
        root: {
          borderRadius: 8,
          backgroundColor: '#222',
          backgroundImage: 'none'
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          lineHeight: 1.6
        }
      }
    }
  }
})

export const TabsBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  backgroundColor: '#181818'
})

export const TypoDark = styled(Typography)(() => ({
  '&.MuiTypography-h1': {
    color: '#222222 !important'
  },
  '&.MuiTypography-h2': {
    color: '#222222 !important'
  },
  '&.MuiTypography-h4': {
    color: '#222222 !important'
  },
  '&.MuiTypography-h6': {
    color: '#444444 !important'
  },
  '&.MuiTypography-body1': {
    color: '#666666 !important'
  },
  '&.MuiTypography-body2': {
    color: '#666666 !important'
  },
  '&.MuiTypography-subtitle1': {
    color: '#666666 !important'
  },
  '&.MuiTypography-subtitle2': {
    color: '#666666 !important'
  }
}))
