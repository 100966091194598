import React, { Fragment } from 'react'
import { Button, ClickAwayListener, Fade, Paper, Popper, MenuList, IconButton } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { IReducer } from '../../interface'
import { logoutAccount } from '../../actions'
import ProfilePicture from './ProfilePicture'
import { FormattedMessage } from 'react-intl'
import '../Nav/NavBar.css'
import { useCookies } from 'react-cookie'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'

const Profile = () => {
  const [, , removeCookies] = useCookies(['country_code', 'temporary_user_id'])
  const navigate = useNavigate()
  const accountReducer = useSelector((state: IReducer) => state.accountReducer)
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLButtonElement>(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  const handleLogout = async () => {
    removeCookies('country_code', { path: '/' })
    removeCookies('temporary_user_id', { path: '/' })
    dispatch(await logoutAccount())
    navigate('/')
  }

  return (
    <Fragment>
      {accountReducer.isAuthenticated === true ? (
        <Fragment>
          <IconButton
            disableTouchRipple
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup='true'
            onClick={handleToggle}
            sx={{ opacity: 1 }}
          >
            <ProfilePicture firstName={accountReducer.userInfo.FirstName} />
          </IconButton>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            placement='bottom-end'
          >
            {({ TransitionProps, placement }) => (
              <Fade {...TransitionProps} style={{ transformOrigin: placement }}>
                <Paper elevation={1} sx={{ bgcolor: '#282828' }}>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      className='nav-menu-list'
                      autoFocusItem={open}
                      id='menu-list-grow'
                      onKeyDown={handleListKeyDown}
                    >
                      <Button
                        disableTouchRipple
                        className='nav-btn nav-align-left'
                        onClick={handleClose}
                        component={Link}
                        to='/user-area'
                        data-cy='userArea-button'
                      >
                        <FormattedMessage id='account.userArea' />
                      </Button>
                      <Button
                        disableTouchRipple
                        className='nav-btn nav-align-left'
                        onClick={(event: any) => {
                          handleClose(event)
                          handleLogout()
                        }}
                      >
                        <FormattedMessage id='account.logOut' />
                      </Button>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Fade>
            )}
          </Popper>
        </Fragment>
      ) : (
        <IconButton color='secondary' disableTouchRipple component={Link} to='/login'>
          <PersonOutlineOutlinedIcon />
        </IconButton>
      )}
    </Fragment>
  )
}

export default Profile
