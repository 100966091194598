import React from 'react'
import './Footer.css'
import { Box, IconButton, Stack } from '@mui/material'
import { Facebook, Instagram, YouTube, LinkedIn } from '@mui/icons-material'
import { BsTwitterX } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import Region from '../Region'

export default function FooterSocial() {
  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      alignItems='center'
      justifyContent='space-between'
      width='100%'
      spacing={2}
      py={2}
    >
      <Stack direction='row' spacing={2}>
        <Link className='footer-icon' to='/'>
          <Box
            component='img'
            alt='Gemvision'
            width={22}
            src='https://gemvision.com/Images/gemvision_logo_g_white.svg'
          />
        </Link>

        <Link
          to={'https://louisianaentertainment.gov/'}
          className='footer-icon'
          target='_blank'
          rel='noopener noreferrer'
        >
          <Box
            component='img'
            alt='Louisiana Entertainment'
            width={76}
            src='https://gemvision.com/Images/Common/louisiana_entertainment_logo_white.svg'
          />
        </Link>
        <Region />
      </Stack>

      <Stack direction='row' spacing={0.5}>
        <IconButton
          className='footer-icon'
          size='small'
          disableRipple
          href='https://www.youtube.com/GemvisionCorp'
          target='_blank'
          rel='noopener noreferrer'
        >
          <YouTube sx={{ color: '#fff' }} />
        </IconButton>
        <IconButton
          className='footer-icon'
          size='small'
          disableRipple
          href='https://www.facebook.com/GemvisionCorp/'
          target='_blank'
          rel='noopener noreferrer'
        >
          <Facebook sx={{ color: '#fff' }} />
        </IconButton>
        <IconButton
          className='footer-icon'
          size='small'
          disableRipple
          href='https://www.instagram.com/gemvisioncorp/'
          target='_blank'
          rel='noopener noreferrer'
        >
          <Instagram sx={{ color: '#fff' }} />
        </IconButton>
        <IconButton
          className='footer-icon'
          size='small'
          disableRipple
          href='https://www.twitter.com/GemvisionCorp'
          target='_blank'
          rel='noopener noreferrer'
        >
          <BsTwitterX style={{ color: '#fff' }} />
        </IconButton>
        <IconButton
          className='footer-icon'
          size='small'
          disableRipple
          href='https://www.linkedin.com/company/gemvision/'
          target='_blank'
          rel='noopener noreferrer'
        >
          <LinkedIn sx={{ color: '#fff' }} />
        </IconButton>
      </Stack>
    </Stack>
  )
}
